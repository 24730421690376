
import { Component, Vue } from 'vue-property-decorator'
import api from '@/utilities/api'
import { DateTime } from 'luxon'
import MissingDocument from '@/pages/documents/MissingDocument'

@Component({
  components: {
    'c-missing-document': MissingDocument,
  },
})
export default class Summary extends Vue {
  docs = []
  loading = false

  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }

  mounted() {
    this.getDocuments()
  }

  getDocuments() {
    this.loading = true
    api(
      'supplier/summary',
      { data: { organisationId: this.supplier.id }, json: true, method: 'POST' },
      undefined
    )
      .then(response => {
        this.docs = response.data
      })
      .then(() => {
        this.loading = false
      })
  }
  displayDate(date: any) {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)
  }
}
